<template>
  <div class="article-list">
    <div
      class="one-article"
      v-for="(onearticle, index) in newarticlelist"
      :key="index"
    >
      <div class="article-img"> 
        <img
          :src="onearticle.image"
          @click="goToArticleDetail(onearticle.id, onearticle.hotNum)"
          style="width:100%;height:100%"
          alt=""
        />
      </div>
      <!-- 
            -->
      <div class="article-container">
        <div class="article-title">
          <span @click="goToArticleDetail(onearticle.id, onearticle.hotNum)"
            >{{ onearticle.title }}
          </span>
        </div>
        <div
          class="article-intro"
          @click="goToArticleDetail(onearticle.id, onearticle.hotNum)"
        >
          <span>{{ onearticle.intro }}</span>
        </div>
        <div class="article-one-info">
          <div class="intro-first">
            <span
              ><img
                class="imged"
                :src="onearticle.imge"
                @click="goToUserDetail(onearticle.userid)"
            /></span>
            <span
              class="author-name"
              @click="goToUserDetail(onearticle.userid)"
            >
              {{ onearticle.name }}</span
            >
            <span class="publish-tihme">{{ onearticle.time }}</span>
          </div>
          <div class="intro-second" v-show="onearticle.keyword">
            <i class="keyword-icon"></i>
            <span
              @click="keyWordSearch(word)"
              class="keyword"
              v-for="(word, index) in JSON.parse(onearticle.keyword)"
              :key="index"
            >
              {{ word }}
            </span>
          </div>
        </div>
        <div class="article-other-info">
          <div class="clo-info">
            <i class="hot-icon"></i>
            <span>{{ onearticle.hotNum }}热度</span>
          </div>
          <!-- <div  class="clo-info">
                        <i class="attention-icon" ></i>
                        <span >{{onearticle.concernNum}}关注</span>
                    </div> -->
          <div class="clo-info">
            <i class="praise-icon"></i>
            <span>{{ onearticle.praiseNum }} 赞</span>
          </div>
          <div class="clo-info">
            <i class="collect-icon"></i>
            <span>{{ onearticle.collectNum }}收藏</span>
          </div>
          <div class="clo-info">
            <i class="comment-icon"></i>
            <span>{{ onearticle.commentNum }}评论</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DELETE, QUERY, UPDATE, UPDATES } from "@/services/dao.js";
import { updateHotnumFn, integralAddOne } from "@/js/common/index.js";
import { Alert } from 'element-ui';
export default {
  props: {
    newarticlelist: {
      type: Array,
      required: true,
    },
  },
  methods: {
    //打开文章详情
    async goToArticleDetail(id, hotNum) {
      ///浏览数加1
      hotNum += 1;
      let datdeds = await UPDATES(
        "post",
        "",
        "   update_TtArticle(where: {id: {_eq: " +
          id +
          "}}, _set: {hotNum: " +
          hotNum +
          "}) { affected_rows  }"
      );
      let user = this.$store.state.userinfo.id;
      if (user) {
        integralAddOne(user);
      }
      this.$router.push({
        path: "/articleDetail",
        query: {
          id: id,
        },
      });
    },
    //打开用户详情
    goToUserDetail(userid) {
      this.$router.push({
        path: "/authorXX",
        query: {
          id: userid,
        },
      });
    },
    keyWordSearch(word) {
      console.log(111111111111);
      this.$router.push({
        path: "/keywordArticle",
        query: {
          searchWord: word,
        },
      });
    },
  },
};
</script>

<style scoped>
.imged {
  border-radius: 50%;
  height: 40px;
  width: 40px;
  cursor: pointer;
}
.article-img:hover,
.article-title > span:hover,
.article-intro > span:hover,
.head-icon:hover,
.author-name:hover {
  cursor: pointer;
}
.article-list {
  padding: 0 22px;
  text-align: left;
  background: white;
}
.article-list .one-article {
  background: white;
  height: 150px;
  padding: 20px 0;
}
/* .one-article:last-of-type .one-article{
    border-bottom:none
} */
.article-list > .one-article {
  overflow: hidden;
  border-bottom: 1px dashed rgba(111, 111, 111, 1);
}
.article-container,
.article-img {
  display: inline-block;
  height: 100%;
  position: relative;
}
.article-img {
  width: 33%;
}
.article-container {
  width: 65%;
  vertical-align: top;
  margin-left: 2%;
  /* background: brown; */
  /* padding-left: 3%; */
}
.article-title > span {
  color: #5e6b73;
  font-size: 18px;
  /* font-weight:bolder; */
  line-height: 24px;
}
.article-intro {
  color: #5e6b73;
  font-size: 14px;
  font-weight: lighter;
}
.article-one-info {
  position: absolute;
  width: 96%;
  height: 30px;
  bottom: 30px;
}
.article-other-info {
  position: absolute;
  bottom: 0px;
  width: 96%;
}
.intro-first {
  display: inline-block;
  position: absolute;
  left: 0px;
  bottom: 0;
}
.intro-first > *,
.intro-second > * {
  vertical-align: middle;
}
.head-icon {
  height: 22px;
  width: 22px;
  /* background-image: url(../../../assets/img/icon-person.png) ; */
  display: inline-block;
}

.author-name,
.collect-num,
.comment-num,
.publish-tihme {
  color: #a5a7b3;
}
.author-name {
  /* border:1px solid red; */
  font-size: 12px;
  margin-left: 5px;
  height: 20px;
  line-height: 20px;
  display: inline-block;
}
.publish-tihme {
  /* border: 1px solid green; */
  line-height: 20px;
  height: 20px;
  display: inline-block;
  /* vertical-align: bottom; */
}
.publish-tihme,
.comment-icon {
  margin-left: 10px;
}
.intro-second {
  display: inline-block;
  position: absolute;
  right: 0px;
  bottom: 0;
}
.intro-second .keyword {
  border: 1px solid rgba(181, 181, 181, 1);
  border-radius: 13px;
  text-align: center;
  font-size: 12px;
  padding: 3px;
  display: inline-block;
  width: 60px;
  height: 20px;
  line-height: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.intro-second .keyword:not(:first-of-type) {
  margin-left: 5px;
}

.keyword-icon {
  height: 18px;
  width: 18px;
  background-image: url(../../../assets/icon/icon.png);
  background-position-x: 518px;
  background-position-y: -38px;
  display: inline-block;
}
.collect-icon {
  height: 18px;
  width: 18px;
  /* background-repeat: no-repeat; */
  background-image: url("../../../assets/icon/sc.png");
  display: inline-block;
  background-size: 18px 18px;
  background-position-x: 1px;
  background-position-y: -0.2px;
  margin-right: 4px;
}

.article-other-info .clo-info {
  width: 20%;
  height: 100%;
  display: inline-block;
  vertical-align: middle;
}
.article-other-info .clo-info * {
  vertical-align: middle;
}
.article-other-info .clo-info span {
  font-size: 14px;
}
.article-other-info span,
.article-one-info span {
  /* color: #020704; */
  color: #585858;
}
.article-one-info .intro-second span:hover {
  cursor: pointer;
  background-color: #fd6e05;
  color: white;
  border-color: white;
}
/* 图标icon */
.hot-icon {
  /* border: 1px solid red; */
  height: 18px;
  width: 18px;
  background-image: url("../../../assets/icon/rd(1).png");
  display: inline-block;
  background-size: 18px 18px;
  background-position-x: 1px;
  background-position-y: -0.2px;
  margin-right: 2px;
}
.attention-icon {
  height: 18px;
  width: 18px;
  background-image: url(../../../assets/icon/icon.png);
  background-position-x: 664px;
  background-position-y: -45px;
  display: inline-block;
}
.praise-icon {
  height: 18px;
  width: 18px;
  /* border: 1px solid green; */
  background-image: url("../../../assets/icon/dz.png");
  display: inline-block;
  background-size: 18px 18px;
  background-position-x: 1px;
  background-position-y: -0.2px;
  margin-right: 4px;
}
.comment-icon {
  height: 18px;
  width: 18px;
  background-image: url("../../../assets/icon/pl.png");
  background-size: 18px 18px;
  background-position-x: 0.8px;
  background-position-y: 0.6px;
  display: inline-block;
  margin-right: 4px;
}
</style>
