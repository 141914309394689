<template>
  <div class="articlePage">
    <div class="up" v-if="newarticlelist.length!=0">
      <div class="count">
        <span>含“{{searchWord}}”的搜索结果为 {{totlePage}} 条</span>
      </div>
    </div>
    <br />
    <div class="content">
      <V-ArticleList :newarticlelist="newarticlelist"></V-ArticleList>
    </div>
    <div
      v-if="newarticlelist.length==0"
      style="font-size: 20px;margin-top:100px; color:#626262"
    >抱歉未找到相关结果</div>
    <br />
    <div class="page-nav">
      <el-pagination
        background
        layout="prev, pager, next"
        :hide-on-single-page="hidePageNav"
        :total="totlePage"
        :page-size="pageSize"
        @prev-click="prevPage"
        @next-click="nextPage"
        @current-change="currentPage"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import ArticleList from "@/components/one-article/type-one/index.vue";
import { DELETE, QUERY, QUERYED } from "@/services/dao.js";
import { showTimeFormat } from "@/js/common/index";
export default {
  data() {
    return {
      searchWord: "",
      hidePageNav: true,
      hotWords: [],
      pageNum: 0,
      pageSize: 12,
      totlePage: 0,
      orderActive: 1,
      newarticlelist: []
    };
  },
  created: function() {
    this.init();
  },
  watch: {
    $route(to, from) {
      //监听路由是否变化
      if (to.query.searchWord != from.query.searchWord) {
        this.init(this.pageSize, 0); //重新加载数据
      }
    }
  },
  methods: {
    async init() {
      this.searchWord = this.$route.query.searchWord;
      this.searchInfo(this.pageSize, 0);
    },
    //前一页
    prevPage(val) {
      this.pageNum = val - 1;
      this.searchInfo(this.pageSize, this.pageSize * this.pageNum);
    },
    //下一页
    nextPage(val) {
      this.pageNum = val - 1;
      this.searchInfo(this.pageSize, this.pageSize * this.pageNum);
    },
    //当前页
    currentPage(val) {
      this.pageNum = val - 1;

      this.searchInfo(this.pageSize, this.pageNum);
    },
    async searchInfo(limit, offest) {
      let info = null;
      if (this.searchWord == "" || this.searchWord == null) {
        info = await QUERYED(
          "post",
          "",
          "  Article(limit: " +
            limit +
            ", offset: " +
            offest +
            ") {  id   userid  imge createTime coverImg   content    commentNum    collectNum    browseNum    authorName    accessory    img    intro    keyword    praiseNum    reference    theme    title        isChoiceness    isRecommend    hotNum    authorId }  Article_aggregate {    aggregate {      count    }  }"
        );
      } else {
        info = await QUERYED(
          "post",
          "",
          "  Article(limit: " +
            limit +
            ", offset: " +
            offest +
            ', where: {title: {_like: "%' +
            this.searchWord +
            '%"}}) {  id   userid coverImg imge createTime    content    commentNum    collectNum    browseNum    authorName    accessory    img    intro    keyword    praiseNum    reference    theme    title        isChoiceness    isRecommend    hotNum    authorId }  Article_aggregate (where: {keyword: {_like: "%' +
            this.searchWord +
            '%"}}){    aggregate {      count    }  }'
        );
      }
      this.newarticlelist.splice(0, this.newarticlelist.length);
      for (let i = 0; i < info.data.Article.length; i++) {
        this.newarticlelist.push({
          title: info.data.Article[i].title,
          intro: info.data.Article[i].intro,
          name: info.data.Article[i].authorName,
          time: showTimeFormat(info.data.Article[i].createTime),
          collectNum: info.data.Article[i].collectNum,
          userId: 2,
          commentNum: info.data.Article[i].commentNum,
          image:
            info.data.Article[i].coverImg.indexOf("https://") != -1 ||
            info.data.Article[i].coverImg.indexOf("http://") != -1
              ? info.data.Article[i].coverImg
              : this.api.LoginURL.concat(info.data.Article[i].coverImg),
          // image: this.api.LoginURL.concat(info.data.Article[i].coverImg),
          id: info.data.Article[i].id,
          hotNum: info.data.Article[i].hotNum,
          praiseNum: info.data.Article[i].praiseNum,
          keyword: info.data.Article[i].keyword,
          imge: this.api.LoginURL.concat(info.data.Article[i].imge),
          userid: info.data.Article[i].userid
        });
      }
      this.totlePage = info.data.Article_aggregate.aggregate.count;
    }
  },
  components: {
    "V-ArticleList": ArticleList
  }
};
</script>

<style  scoped>
@import "~@/styles/page/index.css";
/* 文章显示内容 */
.articlePage .up {
  margin-bottom: 15px;
  text-align: right;
  font-size: 10px;
}
.search-container > .main > div > div {
  display: inline-block;
  width: 800px;
}
.articlePage > .up > div {
  display: inline-block;
  width: 50%;
}
.articlePage > .up > .order {
  text-align: left;
}
.articlePage > .up > .order > .time {
  margin-left: 50px;
  margin-right: 20px;
  cursor: pointer;
}
.articlePage > .up > .order > .relate {
  margin-left: 20px;
  cursor: pointer;
}
.articlePage > .up > .order > .active {
  color: #fd6e05;
}
.articlePage > .up > .count {
  text-align: right;
}
</style>